import '../../boot'
import './index.scss'
import { createApp, ref } from 'vue/dist/vue.esm-bundler'
import Encoder from './encoder'
import AppLayoutMixin from "@/app-layout-mixin";
import EmbedCode from "@/components/embed";


createApp({
    components: { Encoder, EmbedCode },
    mixins: [ AppLayoutMixin ],
    setup() {

        const encoderTypeFromPath = location.pathname.match(/encode\-decode\-([\w+\-]+)[\?\#]?/);
        const encoderType = ref(encoderTypeFromPath ? encoderTypeFromPath[1] : 'base64');

        return {
            encoderType,
        }
    },
}).mount('#app')

export default {

    conv: {
        length: {
            mm: 0.001,
            cm: 0.01,
            m: 1,
            km: 1000,
            in: 0.0254,
            ft: 0.3048,
            yd: 0.9144,
            mi: 1609.344,
            nmi: 1852,
        }
    },

    lengthUnitsGrouped: [
        {
            type: "group",
            label: "Metric",
            key: "metric",
            children: [
                {
                    label: 'millimeters (mm)',
                    value: 'mm'
                },
                {
                    label: 'centimeters (cm)',
                    value: 'cm'
                },
                {
                    label: 'meters (m)',
                    value: 'm'
                },
                {
                    label: 'kilometers (km)',
                    value: 'km'
                },
            ]
        },
        {
            type: "group",
            label: "Imperial",
            key: "imperial",
            children: [
                {
                    label: 'inches (in)',
                    value: 'in'
                },
                {
                    label: 'feet (ft)',
                    value: 'ft'
                },
                {
                    label: 'yards (yd)',
                    value: 'yd'
                },
                {
                    label: 'miles (mi)',
                    value: 'mi'
                },
                {
                    label: 'nautical miles (nmi)',
                    value: 'nmi'
                },
            ]
        },
    ],

    convertLength(value, from, to) {
        return value * this.conv.length[from] / this.conv.length[to]
    },

    ucfirst(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
    },

    extractDataFromURLHash() {
        if (location.hash.indexOf('#%7B%22a%') < 0) {
            return false;
        }
        try {
            return JSON.parse(decodeURIComponent(location.hash.substr(1)))
        } catch (e) {
            return false;
        }
    },

    initFromHashData(ctx, hashData, dataFilterCb) {
        dataFilterCb = dataFilterCb || function(v, k) { return v };
        for (let key in hashData.d) {
            let val = dataFilterCb(hashData.d[key], key);
            if (key.indexOf('.')) {
                ctx[key.substr(0, key.indexOf('.'))][key.substr(key.indexOf('.')+1)] = val
            } else {
                ctx[key] = val
            }
        }
    },

    scrollToElement(el) {
        if (typeof el === 'string') {
            el = document.getElementById(el);
        }
        if (!el) {
            console.log('scrollToElement: element not found');
            return;
        }
        setTimeout(() => {console.log(el.offsetTop);
            window.scroll({
                behavior: 'smooth',
                top: el.offsetTop
            });
        }, 500);

    }
}
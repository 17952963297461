<template>
  <n-card>

    <n-form size="large" label-width="auto">

      <n-form-item label="Input your string to hash">
          <n-input type="textarea"
                   v-model:value="stringFrom"
                   autocomplete="off"
                   placeholder="String to hash"
                   :autofocus="true"
          ></n-input>
      </n-form-item>

      <n-form-item label="Hash type">
        <n-select :options="types"
                  v-model:value="hashType"
                  placeholder="Select hash type"
        ></n-select>
      </n-form-item>

      <n-form-item label="Hash key" v-if="requiresKey">
        <n-input v-model:value="key" placeholder="Input shared secred key"></n-input>
      </n-form-item>

      <div class="btn-wrapper">
        <n-button type="primary"
                  strong secondary
                  :disabled="!stringFrom.length"
                  @click="calculate"
        >Generate {{ hashType.toUpperCase() }}</n-button>
      </div>

      <n-form-item label="Result">
        <n-input type="textarea"
                 v-model:value="result"
                 autocomplete="off"
                 placeholder=""
                 :readonly="true"
                 ref="result"
        ></n-input>
      </n-form-item>

      <div class="btn-wrapper">
        <n-button :disabled="!result.length"
                  @click="copyResult"
        >Copy result</n-button>
      </div>

    </n-form>

  </n-card>
</template>

<script>
import { NButton, NFormItem, NForm, NCard, NInput, NSelect, NIcon } from 'naive-ui'


export default {
  name: 'hasher',
  components: { NButton, NFormItem, NForm, NCard, NInput, NSelect, NIcon },
  props: {
    type: {
      type: String,
      default: 'md5',
    },
  },
  data() {
    return {
      stringFrom: '',
      key: '',
      hashType: this.type,
      result: ''
    };
  },
  setup() {
    return {
      types: [
        { label: 'MD5', value: 'md5' },
        { label: 'SHA1', value: 'sha1' },
        { label: 'SHA256', value: 'sha256' },
        { label: 'SHA224', value: 'sha224' },
        { label: 'SHA512', value: 'sha512' },
        { label: 'SHA384', value: 'sha384' },
        { label: 'SHA3', value: 'sha3' },
        { label: 'RIPEMD160', value: 'ripemd160' },
        { label: 'HMAC-MD5', value: 'hmac-md5' },
        { label: 'HMAC-SHA1', value: 'hmac-sha1' },
        { label: 'HMAC-SHA256', value: 'hmac-sha256' },
        { label: 'HMAC-SHA224', value: 'hmac-sha224' },
        { label: 'HMAC-SHA512', value: 'hmac-sha512' },
        { label: 'HMAC-SHA384', value: 'hmac-sha384' },
        { label: 'HMAC-SHA3', value: 'hmac-sha3' },
        { label: 'HMAC-RIPEMD160', value: 'hmac-ripemd160' },
      ]
    }
  },
  methods: {
    handleHashFunc(hashFunc) {
      this.result = hashFunc.default(this.stringFrom).toString();
    },
    handleHashFuncWithKey(hashFunc) {
      this.result = hashFunc.default(this.stringFrom, this.key).toString();
    },
    calculate() {
      // simple
      if (this.hashType === 'md5') import('crypto-js/md5').then(this.handleHashFunc);
      if (this.hashType === 'sha1') import('crypto-js/sha1').then(this.handleHashFunc);
      if (this.hashType === 'sha256') import('crypto-js/sha256').then(this.handleHashFunc);
      if (this.hashType === 'sha224') import('crypto-js/sha224').then(this.handleHashFunc);
      if (this.hashType === 'sha512') import('crypto-js/sha512').then(this.handleHashFunc);
      if (this.hashType === 'sha384') import('crypto-js/sha384').then(this.handleHashFunc);
      if (this.hashType === 'sha3') import('crypto-js/sha3').then(this.handleHashFunc);
      if (this.hashType === 'ripemd160') import('crypto-js/ripemd160').then(this.handleHashFunc);
      // hmac
      if (this.hashType === 'hmac-md5') import('crypto-js/hmac-md5').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-sha1') import('crypto-js/hmac-sha1').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-sha256') import('crypto-js/hmac-sha256').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-sha224') import('crypto-js/hmac-sha224').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-sha512') import('crypto-js/hmac-sha512').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-sha384') import('crypto-js/hmac-sha384').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-sha3') import('crypto-js/hmac-sha3').then(this.handleHashFuncWithKey);
      if (this.hashType === 'hmac-ripemd160') import('crypto-js/hmac-ripemd160').then(this.handleHashFuncWithKey);
    },
    copyResult() {
      this.$refs.result.select();
      navigator.clipboard.writeText(this.result);
    }
  },
  computed: {
    requiresKey() {
      if (this.hashType.indexOf('hmac') > -1) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style lang="scss">
.btn-wrapper {
  text-align: center;
}
</style>
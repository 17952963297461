import '../../boot'
import './index.scss'
import { createApp, ref } from 'vue/dist/vue.esm-bundler'
import Hasher from './hasher'
import AppLayoutMixin from "@/app-layout-mixin";
import EmbedCode from "@/components/embed";


createApp({
    components: { Hasher, EmbedCode },
    mixins: [ AppLayoutMixin ],
    setup() {

        const hashTypeFromPath = location.pathname.match(/hash\-([\w+\-]+)[\?\#]?/);
        const hashType = ref(hashTypeFromPath ? hashTypeFromPath[1] : 'md5');

        return {
            hashType,
        }
    },
}).mount('#app')

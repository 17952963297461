<template>
  <div>

    <n-input
        :value="code"
        type="textarea"
        :readonly="true"
        ref="code"
    />

    <div class="embedcode-btn-wrapper">
      <n-button @click="copy">Copy code</n-button>
    </div>

  </div>
</template>

<script>
import { NInput, NButton } from 'naive-ui'

export default {
  name: 'embed-code',
  components: { NInput, NButton },
  props: {
    code: String
  },
  methods: {
    copy() {
      this.$refs.code.select();
      navigator.clipboard.writeText(this.code);
    }
  }
}
</script>

<style lang="scss">
.embedcode-btn-wrapper {
  margin-top: 10px;
  text-align: right;
}
</style>
import '../../boot'
import './index.scss'
import { createApp, ref } from 'vue/dist/vue.esm-bundler'
import Converter from './converter'
import ConvTable from './conv-table'
import AppLayoutMixin from "@/app-layout-mixin";
import EmbedCode from "@/components/embed";


createApp({
    components: { Converter, ConvTable, EmbedCode },
    mixins: [ AppLayoutMixin ],
    setup() {

        const unitsFromPath = location.pathname.match(/convert\-(\w+)\-to\-(\w+)/);

        const unitFrom = ref(unitsFromPath ? unitsFromPath[1] : null);
        const unitTo = ref(unitsFromPath ? unitsFromPath[2] : null);

        return {
            unitFrom,
            unitTo,
        }
    },
}).mount('#app')

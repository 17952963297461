import '../../boot'
import './index.scss'
import { createApp } from 'vue/dist/vue.esm-bundler'
import AppLayoutMixin from '../../app-layout-mixin'
import EmbedCode from '../../components/embed'
import { NButton, NDropdown } from 'naive-ui'

const STORE_HISTORY_KEY = 'h';

createApp({
    components: { NButton, NDropdown, EmbedCode },
    mixins: [ AppLayoutMixin ],
    data() {
        return {
            input: '',
            resultPreview: '',
            history: [],
            options: [
                {
                    label: 'Copy',
                    key: 'copy',
                    disabled: true
                },
                {
                    label: "Paste",
                    key: "paste"
                }
            ],
        }
    },
    created() {
        this.loadHistory();
        if (this.history.length) {
            this.scrollBottomHistory();
        }
        this.initKeyboardEvents();
    },
    methods: {
        write(symbol){
            this.input += symbol;
        },
        fn(fn) {
            if (typeof fn === 'function') {
                this.input = fn(this.input);
            } else {
                this.input = this[fn](this.input);
            }
        },
        reset(){
            this.input = '';
        },
        backspace() {
            this.input = this.input.slice(0, -1);
        },
        evalInput(input) {
            if (!input.length) {
                return '';
            }
            return eval(
                input
                    .replace('%', '*100')
                    .replace('^', '**')
            );
        },
        eval() {
            let result = null;
            try {
                result = this.evalInput(this.input);
            } catch (e) {
                alert('Error. Check input'/* + e.message*/);
                return;
            }
            this.pushToHistory(this.input, result);
            this.input = result.toString();
        },
        createResultPreview() {
            let preview = false;
            try {
                preview = this.evalInput(this.input);
            } catch (e) {
                return '';
            }
            if (!preview || preview == this.input) {
                return '';
            }
            return '=' + preview;
        },
        scrollBottomHistory() {
            this.$nextTick(() => {
                this.$refs.history.scrollTo(0, this.$refs.history.scrollHeight+50);
            });
        },
        pushToHistory(input, result) {
            if (!result || result == input) {
                return;
            }
            this.history.push({
                i: input,
                r: result
            });
            this.scrollBottomHistory();
            this.storeHistory();
        },
        storeHistory() {
            if (typeof localStorage === 'undefined') {
                return;
            }
            localStorage.setItem(STORE_HISTORY_KEY, JSON.stringify(this.history.slice(-30)));
        },
        loadHistory() {
            if (typeof localStorage === 'undefined') {
                return;
            }
            this.history = JSON.parse(localStorage.getItem(STORE_HISTORY_KEY)) || [];
        },
        clearHistory() {
            this.history = [];
            this.storeHistory();
        },
        initKeyboardEvents() {

            window.addEventListener( "keydown", (event) => {
                if (event.key === undefined) {
                    return;
                }
                if (event.key.match(/[0-9\+\-\*\/\%\(\)\.]/) && event.key.length === 1) {
                    this.write(event.key);
                    return;
                }
                if (event.key === ',') {
                    this.write('.');
                    return;
                }
                if (event.key === '=' || event.key === 'Enter') {
                    this.eval();
                    return;
                }
                if (event.key === 'Backspace') {
                    this.backspace();
                    return;
                }
                // console.log(event.key, event.keyCode);
            },true);

            window.addEventListener( "paste", (event) => {
                event.preventDefault();
                let paste = (event.clipboardData || window.clipboardData).getData("text");
                paste = this.filterInput(paste);
                this.write(paste);
                return;
            });
        },

        filterInput(input) {
            input = input.replace(',', '.');
            input = (input.match(/[0-9\+\-\*\/\%\(\)\.]+/g) || []).join('');
            return input;
        },

        handleSelect(key) {
            switch (key) {
                case 'copy':
                    navigator.clipboard.writeText(this.input);
                    break;
                case 'paste':
                    navigator.clipboard.readText().then(text => {
                        this.write(this.filterInput(text));
                    });
                    break;
            }
        },

        Inverse(a) {
            return 1/a;
        },

        Sign(a) {
            return a * -1;
        }
    },
    watch: {
        input() {
            this.resultPreview = this.createResultPreview();
            this.options[0].disabled = !this.input.length;
        }
    }
}).mount('#app')

<template>
  <n-card>

    <n-form size="large" label-width="auto">

      <n-form-item label="Enter value and unit for convert from">

        <n-input-group>

          <n-input-number :style="{ width: '65%' }"
                          v-model:value="valueFrom"
                          :show-button="false"
                          placeholder="Enter value"
                          name="valueFrom"
                          autocomplete="off"
                          :autofocus="true"
          ></n-input-number>

          <n-select :style="{ width: '35%' }"
                    :options="units"
                    v-model:value="unitFrom"
                    placeholder="Select unit"
          ></n-select>

        </n-input-group>

      </n-form-item>

      <n-form-item label="Convert to unit">
        <n-select :options="units" v-model:value="unitTo" placeholder="Select unit"></n-select>
      </n-form-item>

    </n-form>

    <div class="result">
      <span v-if="result">
        <h3>Result:</h3>
        {{ valueFrom }} {{ unitFrom }} = {{ result }} {{ unitTo}}
      </span>
    </div>

    <template #action>
      <n-button icon-placement="left" @click="swapUnits()">
        <template #icon>
          <n-icon>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 19V4m0 0 3 3M8 4 5 7m11-2v15m0 0 3-3m-3 3-3-3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </n-icon>
        </template>
        Swap units
      </n-button>
    </template>

  </n-card>
</template>

<script>
import { NButton, NFormItem, NForm, NCard, NInputGroup, NInputNumber, NSelect, NIcon } from 'naive-ui'
import helper from "@/helper";


export default {
  name: 'converter',
  components: { NButton, NFormItem, NForm, NCard, NInputGroup, NInputNumber, NSelect, NIcon },
  props: {
    initialUnitFrom: {
      type: String,
      default: null,
    },
    initialUnitTo: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      valueFrom: 1,
      unitFrom: this.initialUnitFrom,
      unitTo: this.initialUnitTo,
      units: helper.lengthUnitsGrouped,
    };
  },
  computed: {
    result() {
      if (!this.valueFrom) {
        return null;
      }
      return helper.convertLength(this.valueFrom, this.unitFrom, this.unitTo);
    }
  },
  methods: {
    swapUnits() {
      [ this.unitFrom, this.unitTo ] = [ this.unitTo, this.unitFrom ];
    }
  }
}
</script>
<template>
  <n-card>

    <n-form size="large" label-width="auto">

      <n-form-item label="Input your string">
          <n-input type="textarea"
                   v-model:value="stringFrom"
                   autocomplete="off"
                   placeholder="String for encode or decode"
                   :autofocus="true"
          ></n-input>
      </n-form-item>

      <n-form-item label="Encoder type">
        <n-select :options="types"
                  v-model:value="encoderType"
                  placeholder="Select encoder type"
        ></n-select>
      </n-form-item>

      <n-space justify="center" class="btn-wrapper">

        <n-button type="primary"
                  strong secondary
                  :disabled="!stringFrom.length"
                  @click="encode"
        >Encode to {{ ucfirst(encoderType) }}</n-button>

        <n-button type="primary"
                  strong secondary
                  :disabled="!stringFrom.length"
                  @click="decode"
        >Decode from {{ ucfirst(encoderType) }}</n-button>

      </n-space>

      <n-form-item label="Result">
        <n-input type="textarea"
                 v-model:value="result"
                 autocomplete="off"
                 placeholder=""
                 :readonly="true"
                 ref="result"
        ></n-input>
      </n-form-item>

      <div class="btn-wrapper">
        <n-button :disabled="!result.length"
                  @click="copyResult"
        >Copy result</n-button>
      </div>

    </n-form>

  </n-card>
</template>

<script>
import { NButton, NFormItem, NForm, NCard, NInput, NSelect, NSpace } from 'naive-ui'
import helper from "@/helper";


export default {
  name: 'encoder',
  components: { NButton, NFormItem, NForm, NCard, NInput, NSelect, NSpace },
  props: {
    type: {
      type: String,
      default: 'base64',
    },
  },
  data() {
    return {
      stringFrom: '',
      encoderType: this.type,
      result: ''
    };
  },
  setup() {
    return {
      types: [
        { label: 'Base64', value: 'base64' },
        { label: 'Hex', value: 'hex' },
      ]
    }
  },
  methods: {
    handleEncode() {
      import('crypto-js/core').then(CryptoJS => {
        this.result = CryptoJS.enc[ helper.ucfirst(this.encoderType) ].stringify(
            CryptoJS.enc.Utf8.parse(this.stringFrom)
        ).toString();
      });
    },
    handleDecode(encoder) {
      import('crypto-js/core').then(CryptoJS => {
        this.result = CryptoJS.enc.Utf8.stringify(
            CryptoJS.enc[this.encoderType.charAt(0).toUpperCase() + this.encoderType.substring(1)].parse( this.stringFrom )
        );
      });
    },
    initModule(cb) {
      if (this.encoderType === 'base64') {
        import('crypto-js/enc-base64').then(cb);
        return;
      }
      cb();
    },
    encode() {
      this.initModule(this.handleEncode);
    },
    decode() {
      this.initModule(this.handleDecode);
    },
    copyResult() {
      this.$refs.result.select();
      navigator.clipboard.writeText(this.result);
    },
    ucfirst(str) {
      return helper.ucfirst(str);
    }
  }
}
</script>

<style lang="scss">
.btn-wrapper {
  margin-bottom: 26px;
}
</style>
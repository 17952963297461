import "./assets/app.scss";
// import './theme-toggle'

if (process.env.NODE_ENV === 'production') {
    if (location.pathname.indexOf('embed') < 0) {
        // GetStat
        const gs = document.createElement('script');
        gs.src = 'https://s.getstat.net/cdn/client.js';
        gs.async = true;
        gs.id = 'getstat';
        gs.setAttribute('data-id', 93);
        gs.setAttribute('data-domain', 'itscalculator.com');
        document.head.appendChild(gs);
    }
    // GTAG
    const ga = document.createElement('script');
    ga.src = 'https://www.googletagmanager.com/gtag/js?id=G-24WL7959J7';
    ga.async = true;
    document.head.appendChild(ga);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-24WL7959J7');
}

if ('serviceWorker' in navigator && location.pathname.indexOf('embed') < 0) {
    window.addEventListener('load', async () => {
        navigator.serviceWorker.register('/sw.js');
    });
}
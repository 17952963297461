import { h } from 'vue';
import { NConfigProvider, darkTheme, NMenu, NInput, NIcon } from 'naive-ui'
import { useDark, useToggle } from "@vueuse/core";

const isDark = useDark()
const toggleDark = useToggle(isDark)

export default {

    components: { NConfigProvider, NMenu, NInput, NIcon },
    data() {
        return {
            menuOptions: [
                {
                    label: "Math",
                    key: "math",
                    children: [
                        {
                            label: () => h('a',{ href: '/', }, 'Simple calculator'),
                            key: "/",
                        },
                        {
                            label: () => h('a',{ href: '/scientific-calculator', }, 'Scientific calculator'),
                            key: "/scientific-calculator",
                        },
                        {
                            label: () => h('a',{ href: '/percentage', }, 'Percentage calculator'),
                            key: "/percentage",
                        },
                        {
                            label: () => h('a',{ href: '/fractions', }, 'Fractions calculator'),
                            key: "/fractions",
                        }
                    ]
                },
                {
                    label: "Finance",
                    key: "finance",
                    children: [
                        {
                            label: () => h('a',{ href: '/sip-calculator', }, 'SIP calculator'),
                            key: "/sip-calculator",
                        },
                    ]
                },
                {
                    label: "GPA",
                    key: "gpa",
                    children: [
                        {
                            label: () => h('a',{ href: '/college-gpa', }, 'College GPA calculator'),
                            key: "/college-gpa",
                        }
                    ]
                },
                {
                    label: "Health & fitness",
                    key: "health",
                    children: [
                        {
                            label: () => h('a',{ href: '/bmi', }, 'BMI calculator'),
                            key: "/bmi",
                        },
                        {
                            label: () => h('a',{ href: '/one-rep-max', }, 'One Rep Max (1RM)'),
                            key: "/one-rep-max",
                        },
                    ]
                },
                {
                    label: "Designer",
                    key: "dev",
                    children: [
                        {
                            label: () => h('a',{ href: '/color-contrast', }, 'Color contrast'),
                            key: "/color-contrast",
                        },
                        {
                            label: () => h('a',{ href: '/color-luminance', }, 'Color luminance'),
                            key: "/color-luminance",
                        }
                    ]
                },
                {
                    label: "Area",
                    key: "area",
                    children: [
                        {
                            label: () => h('a',{ href: '/rectangle-area', }, 'Rectangle area'),
                            key: "/rectangle-area",
                        },
                        {
                            label: () => h('a',{ href: '/triangle-area', }, 'Triangle area'),
                            key: "/triangle-area",
                        },
                        {
                            label: () => h('a',{ href: '/circle-area', }, 'Circle area'),
                            key: "/circle-area",
                        },
                        {
                            label: () => h('a',{ href: '/trapezoid-area', }, 'Trapezoid area'),
                            key: "/trapezoid-area",
                        }
                    ]
                },
                {
                    label: "Text",
                    key: "text",
                    children: [
                        {
                            label: () => h('a',{ href: '/string-length', }, 'String length'),
                            key: "/string-length",
                        },
                    ]
                },
                {
                    label: "Date",
                    key: "date",
                    children: [
                        {
                            label: () => h('a',{ href: '/age', }, 'Age calculator'),
                            key: "/age",
                        },
                        {
                            label: () => h('a',{ href: '/duration', }, 'Duration calculator'),
                            key: "/duration",
                        }
                    ]
                },
                {
                    label: "Length converter",
                    key: "converter-length",
                    children: [
                        {
                            label: () => h('a',{ href: '/convert-mm-to-in', }, 'Mm to Inches'),
                            key: "/convert-mm-to-in",
                        },
                        {
                            label: () => h('a',{ href: '/convert-cm-to-in', }, 'Cm to Inches'),
                            key: "/convert-cm-to-in",
                        },
                        {
                            label: () => h('a',{ href: '/convert-km-to-mi', }, 'Km to Miles'),
                            key: "/convert-km-to-mi",
                        },
                        {
                            label: () => h('a',{ href: '/convert-in-to-mm', }, 'Inches to Mm'),
                            key: "/convert-in-to-mm",
                        },
                        {
                            label: () => h('a',{ href: '/convert-in-to-cm', }, 'Inches to Cm'),
                            key: "/convert-in-to-cm",
                        },
                        {
                            label: () => h('a',{ href: '/convert-mi-to-km', }, 'Miles to Km'),
                            key: "/convert-mi-to-km",
                        },
                    ]
                },
                {
                    label: "Encode/decode",
                    key: "encode",
                    children: [
                        {
                            label: () => h('a',{ href: '/encode-decode-base64', }, 'Base64'),
                            key: "/encode-decode-base64",
                        },
                        {
                            label: () => h('a',{ href: '/encode-decode-hex', }, 'Hex'),
                            key: "/encode-decode-hex",
                        },
                    ]
                },
                {
                    label: "Hash",
                    key: "hash",
                    children: [
                        {
                            label: () => h('a',{ href: '/hash-md5', }, 'MD5'),
                            key: "/hash-md5",
                        },
                        {
                            label: () => h('a',{ href: '/hash-sha1', }, 'SHA1'),
                            key: "/hash-sha1",
                        },
                        {
                            label: () => h('a',{ href: '/hash-sha256', }, 'SHA256'),
                            key: "/hash-sha256",
                        },
                        {
                            label: () => h('a',{ href: '/hash-sha224', }, 'SHA224'),
                            key: "/hash-sha224",
                        },
                        {
                            label: () => h('a',{ href: '/hash-sha512', }, 'SHA512'),
                            key: "/hash-sha512",
                        },
                        {
                            label: () => h('a',{ href: '/hash-sha384', }, 'SHA384'),
                            key: "/hash-sha384",
                        },
                        {
                            label: () => h('a',{ href: '/hash-sha3', }, 'SHA3'),
                            key: "/hash-sha3",
                        },
                        {
                            label: () => h('a',{ href: '/hash-ripemd160', }, 'RIPEMD160'),
                            key: "/hash-ripemd160",
                        },

                        {
                            label: () => h('a',{ href: '/hash-hmac-md5', }, 'HMAC-MD5'),
                            key: "/hash-hmac-md5",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-sha1', }, 'HMAC-SHA1'),
                            key: "/hash-hmac-sha1",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-sha256', }, 'HMAC-SHA256'),
                            key: "/hash-hmac-sha256",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-sha224', }, 'HMAC-SHA224'),
                            key: "/hash-hmac-sha224",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-sha512', }, 'HMAC-SHA512'),
                            key: "/hash-hmac-sha512",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-sha384', }, 'HMAC-SHA384'),
                            key: "/hash-hmac-sha384",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-sha3', }, 'HMAC-SHA3'),
                            key: "/hash-hmac-sha3",
                        },
                        {
                            label: () => h('a',{ href: '/hash-hmac-ripemd160', }, 'HMAC-RIPEMD160'),
                            key: "/hash-hmac-ripemd160",
                        },
                    ]
                },
                {
                    label: "Network",
                    key: "network",
                    children: [
                        {
                            label: () => h('a',{ href: '/ip-subnet', }, 'IP subnet'),
                            key: "/ip-subnet",
                        },
                    ]
                },
                {
                    key: 'd1',
                    type: 'divider',
                },
                {
                    type: "group",
                    label: "About",
                    key: "about",
                    children: [
                        {
                            label: () => h('a',{ href: '/privacy-policy', }, 'Privacy Policy'),
                            key: "/privacy-policy",
                        },
                        {
                            label: () => h('a',{ href: '/terms', }, 'Terms of Use'),
                            key: "/terms",
                        },
                        {
                            label: () => h('a',{ href: 'mailto:info@itscalculator.com', }, 'Contact'),
                            key: "/contact",
                        }
                    ]
                },
            ],
            selectedMenuItem: location.pathname.replace(location.search, ''),
            menuOpened: false,
            searchQuery: ''
        }
    },
    computed: {
        theme() {
            return isDark.value ? darkTheme : null;
        },
        menuOptionsFiltered() {
            if (!this.searchQuery.trim().length) {
                return this.menuOptions;
            }
            let items = Array.from(this.menuOptions);

            return items
                .map(group => {
                    if (!('children' in group)) {
                        return group;
                    }
                    return {
                        ...group,
                        type: 'group',
                        children:  group.children.filter(item => {
                            return item.key.replace('-', ' ').indexOf(this.searchQuery.toLowerCase()) > -1
                        })
                    }
                })
                .filter(group => {
                    return !('children' in group) || group.children.length;
                })

        }
    },
    // mounted() {
    //     window
    //         .matchMedia('(prefers-color-scheme: dark)')
    //         .addEventListener('change', ({ matches: dark}) => {
    //             const selectedTheme = ('localStorage' in window) ? localStorage.getItem('vueuse-color-scheme') : 'auto';
    //             if (selectedTheme === 'auto') {
    //                 // isDark.value = dark;
    //                 console.log(dark);
    //             }
    //         })
    // },
    methods: {
        toggleMenu() {
            this.menuOpened = !this.menuOpened;
        },
        switchTheme() {
            toggleDark();console.log(isDark.value);
            // this.theme = isDark.value ? darkTheme : null;
        }
    }

}
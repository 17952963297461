<template>
  <div>
    <h2><b>{{ unitFrom }}</b> to <b>{{ unitTo }}</b> conversion table</h2>

    <n-table :bordered="false" :single-line="false">
      <thead>
      <n-tr>
        <n-th>{{ unitFrom }}</n-th>
        <n-th>{{ unitTo }}</n-th>
      </n-tr>
      </thead>
      <tbody>
        <n-tr v-for="row in table">
          <n-td>{{ row[0]}}</n-td>
          <n-td>{{ row[1]}}</n-td>
        </n-tr>
      </tbody>
    </n-table>
  </div>
</template>

<script>
import { NTable, NTh, NTr, NTd } from 'naive-ui'
import helper from "@/helper";


export default {
  name: 'conv-table',
  components: { NTable, NTh, NTr, NTd },
  props: {
    unitFrom: {
      type: String,
      default: null,
    },
    unitTo: {
      type: String,
      default: null,
    }
  },
  data() {

    const table = [
      [0.1],
      ...Array.from({ length: 9 }, (value, index) => [ index+1 ]),
      ...Array.from({ length: 10 }, (value, index) => [ (index+1)*10 ])
    ];

    table.map(row => {
      row.push(helper.convertLength(row[0], this.unitFrom, this.unitTo));
      return row;
    });

    return {
      table,
    };
  },

}
</script>